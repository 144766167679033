import "bootstrap/dist/css/bootstrap.min.css";
import "./page.css";

function HomePage() {
  const background = {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100%",
    maxWidth: "100%",
    height: "800px",
    maxHeight: "100%",
    position: "middle",
    opacity: "1",
  };
  const title = {
    width: "60%",
    marginLeft: "20%",
    color: "black",
    textAlign: "center",
    textSize: "5rem",
    marginTop: "19rem",
    marginBottom: "1rem",
    fontweight: "bold",
  };
  const ancoument ={
    textSize: "4rem",
    textAlign: "Center",
  };

  return (
    <div>
      {/* LANDING PAGE */}
      <div className="container" style={background}>
        <div className="row">
            <h1 style={title} id="welcome">
              Signups
            </h1>
          <div className="row">
            <div
              className="custom-separator"
              style={{title, width: "60%", marginLeft: "20%" }}
            ></div>
          </div>
	  <div>
	  <ul style={ancoument}>
	  	<ul>Sign up through the following link: <a href="https://forms.gle/v1gNwQRa7jSxTUcAA">https://forms.gle/v1gNwQRa7jSxTUcAA</a></ul>
	  </ul>
	  </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
